import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import TeamContactCard from "../components/team-contact-card"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const TeamMemberTemplate = ({ data, pageContext, location }) => {
  const { person } = data;

  const sm = {span: 8, offset: 2};

  return (
    <Layout>
      <SEO
        title={person.name}
        description={person.description}
      />

      <article>
        <header>
          <Row>
            <Col xs={12} sm={sm} md={{span: 4, offset: 3}} lg={{span: 6, offset: 2}}>
              <h1>{person.name}</h1>
            </Col>
          </Row>

        </header>

        <section>
          <Row>
            <Col md={3} lg={2}>
              <GatsbyImage
                image={person.photo.gatsbyImageData}
                alt={person.name}
                className='mb-3 rounded d-none d-md-flex' />
            </Col>
            <Col xs={12} sm={sm} md={{span: 8, offset: 0}} lg={6}>
              <GatsbyImage
                image={person.photo.gatsbyImageData}
                alt={person.name}
                className='mb-3 rounded float-right team-member-mini-photo d-md-none' />
              {renderRichText(person.bio)}
            </Col>
            <Col sm={sm} md={{span: 6, offset: 3}} lg={{span: 4, offset: 0}}>
              {person.active &&
                <TeamContactCard person={person} smallEmail={true} />
              }
            </Col>
          </Row>
        </section>
      </article>
    </Layout>
  );
}

export default TeamMemberTemplate;

export const pageQuery = graphql`query TeamMemberBySlug($slug: String!) {
  person: contentfulPerson(slug: {eq: $slug}) {
    email
    phoneNumber
    linkedIn
    slug
    active
    name
    description
    languages
    bio {
      raw
    }
    photo {
      gatsbyImageData(layout: CONSTRAINED, height: 286, width: 220)
    }
  }
}
`
